<template>
  <div class="container">
    <div class="header">
      <img src="../assets/header-icon.png" alt="Image 1" style="width: 40px;height: 40px;margin-right: 17px;">
      <p style="font-size: 20px; color: #333; font-weight: 600;">碗跳塔pro</p>
    </div>
    <div class="contentContainer">
      <div class="content-first">
      <p style="font-size: 70px; color: #333; font-weight: 600; margin-top: 90px;margin-bottom: 0;line-height: 1;">碗跳塔pro</p>
      <div style="display: flex; flex-direction: row;margin-top: 30px;">
        <vertical-line-with-text text="攻略分享" :showVerticalLine="false"></vertical-line-with-text>
        <vertical-line-with-text text="保护隐私" :showVerticalLine="true"></vertical-line-with-text>
        <vertical-line-with-text text="笔记简洁" :showVerticalLine="true"></vertical-line-with-text>
        <vertical-line-with-text text="上线快捷" :showVerticalLine="true"></vertical-line-with-text>
      </div>
    </div>
    <div class="content-second">
      <p style="font-size: 40px;font-weight: 500;margin-top: 80px;color: #000;margin-bottom: 0;">使用碗跳塔pro，你的游戏时间管理的大师</p>
      <p style="font-size: 20px;color: #797979;">随时随地的上线，分享，讨论游戏，让游戏理解永远快人一步</p>
      <div style="display: flex;flex-direction: row;justify-content: center;margin-bottom: 100px;margin-top: 40px;">
        <vertical-image-with-text v-for="(item,index) in firstItems" 
        :key="item.id"
        :imageUrl="item.imageUrl"
        :firstText="item.firstText"
        :secondText="item.secondText"
        :style="{marginRight:index < firstItems.length -1 ? '100px':'0',width:'182px'}"
        ></vertical-image-with-text>
      </div>
    </div>
    <div class="content-third">
      <div style="display: flex;flex-direction: row;justify-content: center;margin-top: 80px;" >
        <icon-left-text v-for="(item,index) in secondItems"
         :key="item.id"
         :iconSrc="item.imageUrl"
         :labelText="item.firstText"
         :style="{marginRight:index < secondItems.length -1 ? '30px':'0',width:'124px'}"
         ></icon-left-text>
      </div>
      <img src="../assets/third-mid.webp" alt="" style="width: 640px;height: 520px;">
      <p style="font-size: 40px;font-weight: 500px;color: #000;margin-top: 30px;margin-bottom: 100px;">高效管理待办事项</p>
    </div>
    <div class="content-four">
      <p style="font-size: 40px;font-weight: 500;margin-top: 80px;color: #000;margin-bottom: 0;">模块化体验，自由编辑</p>
      <p style="font-size: 20px;color: #797979;">多种特色功能提升你的游戏升级效率</p>
      <div style="display: flex;flex-direction: row;justify-content: center;margin-bottom: 30px;margin-top: 40px;">
        <vertical-image-with-text v-for="(item,index) in fourItems" 
        :key="item.id"
        :imageUrl="item.imageUrl"
        :firstText="item.firstText"
        :secondText="item.secondText"
        :style="{marginRight:index < fourItems.length -1 ? '100px':'0',width:'182px'}"
        ></vertical-image-with-text>
      </div>
      <div style="display: flex;flex-direction: row;justify-content: center;margin-bottom: 100px;">
        <vertical-image-with-text v-for="(item,index) in fiveItems" 
        :key="item.id"
        :imageUrl="item.imageUrl"
        :firstText="item.firstText"
        :secondText="item.secondText"
        :style="{marginRight:index < fiveItems.length -1 ? '100px':'0',width:'182px'}"
        ></vertical-image-with-text>
      </div>
    </div>
    <div class="content-five">
      <p style="font-size: 40px;font-weight: 500;margin-top: 80px;color: #000;margin-bottom: 0;">让等级更高，让装备更优，让战队人员配备更合理</p>
      <p style="font-size: 20px;color: #797979;">帮助每一个玩家能够在游戏内合理规划，提前组队，抢夺装备，让碗跳塔pro成为你的第二游戏攻略</p>
      <div style="display: flex;flex-direction: row;justify-content: center;margin-bottom: 100px;margin-top: 40px;">
       <img src="../assets/content-five-1.webp" alt="" style="width: 276px;height: 310px;margin-right: 100px;">
       <img src="../assets/content-five-2.webp" alt="" style="width: 276px;height: 310px;margin-right: 100px;">
       <img src="../assets/content-five-3.webp" alt="" style="width: 276px;height: 310px;">
      </div>
    </div>
    <div class="content-six">
      <p style="font-size: 40px;font-weight: 500;margin-top: 80px;color: #000;margin-bottom: 0;">帮助数万用户，更高效的提升游戏水平</p>
      <p style="font-size: 20px;color: #797979;">已为全游戏社区2.5w人提供服务</p>
      <div style="display: flex;flex-direction: row;justify-content: center;margin-bottom: 100px;margin-top: 40px;">
       <p style="font-size: 16px;color: #000;width: 500px;text-align: left;">用了7天，碗跳塔pro里有30条装备升级标签，直接从原来的战力榜垫底一路飙升至前三.实现了实现了平民玩家赶超氪金大佬</p>
       <img src="../assets/content-siv-icon.png" alt="" style="width: 100px;height: 100px;margin-left: 40px;">
      </div>
    </div>
    <div class="content-seven">
    <img src="../assets/content-seven-icon.png" alt="" style="width: 400px;height: 280px;">
    <div style=" justify-content: start;display: flex;flex-direction: column;margin-left: 30px;">
      <p style="font-size: 40px;font-weight: 500;margin-top: 80px;color: #000;margin-bottom: 0;">使用碗跳塔pro，记录一切游戏内容</p>
      <p style="font-size: 20px;color: #797979;text-align: left;">支持所有设备，随时随地保持高效</p>
    </div>
    </div>
    <div class="content-eight">
      <p style="font-size: 40px;font-weight: 500;margin-top: 80px;color: #000;margin-bottom: 0;">公司介绍</p>
      <p style="font-size: 20px;color: #797979;margin-left: 14%;margin-right: 14%;">我们是一家专注于手机游戏开发的公司，自成立以来，始终秉持着“创新、专业、共赢”的理念，致力于为广大玩家带来最优质的游戏体验。</p>
      <div class="image-container">
       <img src="../assets/introduce-1.webp" alt="Image 1">
       <img src="../assets/introduce-2.webp" alt="Image 2">
       <img src="../assets/introduce-3.webp" alt="Image 3">
      </div>
    </div>
    </div>
    <div class="footer">
      <div class="contact">
         <contact-text v-for="(item,index) in contactItems"
         :key="index"
         :iconSrc = "item.imageUrl"
         :labelText ="item.firstText" style="margin-left:25px;margin-right: 25px;">
        </contact-text>
      </div>
      <p style="font-size: 12px; color: #fff; font-weight: 400;">成都云端快发有量网络科技有限公司 版权所有
        <a href="#" @click="redirectToICP" target="_blank">蜀ICP备2022028976号</a>
      </p>
    </div>
  </div>
</template>
<script>
import VerticalLineWithText from '@/view/VerticalLineWithText';
import VerticalImageWithText from '@/view/VerticalImageWithText';
import IconLeftText from '@/view/IconLeftText'
import ContactText from '@/view/ContactText'
import contentSecond1 from '../assets/content-second-1.png'
import contentSecond2 from '../assets/content-second-2.png'
import contentSecond3 from '../assets/content-second-3.png'
import contentSecond4 from '../assets/content-second-4.png'
import contentThird1 from '../assets/content-third-1.png';
import contentThird2 from '../assets/content-third-2.png';
import contentThird3 from '../assets/content-third-3.png';
import contentThird4 from '../assets/content-third-4.png';
import contentThird5 from '../assets/content-third-5.png';
import contentThird6 from '../assets/content-third-6.png';
import contentFour1 from '../assets/content-four-1.png';
import contentFour2 from '../assets/content-four-2.png';
import contentFour3 from '../assets/content-four-3.png';
import contentFour4 from '../assets/content-four-4.png';
import contentFive1 from '../assets/content-five-1.png';
import contentFive2 from '../assets/content-five-2.png';
import contentFive3 from '../assets/content-five-3.png';
import contentFive4 from '../assets/content-five-4.png';
import contactPhone from '../assets/iphone-icon.png';
import contactMail from '../assets/mail-icon.png';

export default {
  name: 'VueUI',
  methods: {
    redirectToICP(){
      window.open('https://beian.miit.gov.cn', '_blank');
    }
  },
  components:{
    VerticalLineWithText,
    VerticalImageWithText,
    IconLeftText,
    ContactText,
  },
  data(){
    return{
      firstItems:[
      {
          id: 1,
          imageUrl: contentSecond1,
          firstText: '多端同步',
          secondText: '随时随地查看游戏信息'
        },
        {
          id: 2,
          imageUrl: contentSecond2,
          firstText: '任务签到',
          secondText: '不遗漏每一个游戏日常'
        },
        {
          id: 3,
          imageUrl: contentSecond3,
          firstText:  '道具管理',
          secondText: '合理规划自己的游戏道具'
        },
        {
          id: 4,
          imageUrl: contentSecond4,
          firstText: '攻略分享',
          secondText: '合理的操作步骤更轻松的通关'
        }
      ],
      secondItems:[
      {
          id: 1,
          imageUrl: contentThird1,
          firstText: '萌宠升级',
        },
        {
          id: 2,
          imageUrl: contentThird2,
          firstText: '战队管理',
          
        },
        {
          id: 3,
          imageUrl: contentThird3,
          firstText:  '装备强化',
          
        },
        {
          id: 4,
          imageUrl: contentThird4,
          firstText: '家园升级',
        }
        ,
        {
          id: 5,
          imageUrl: contentThird5,
          firstText: '阵法炼丹',
        }
        ,
        {
          id: 6,
          imageUrl: contentThird6,
          firstText: '更多',
        }
      ],
      fourItems:[
      {
          id: 1,
          imageUrl: contentFour1,
          firstText: '萌宠升级',
          secondText: '提升宝宝属性，让宠物战力提升前列',
        },
        {
          id: 2,
          imageUrl: contentFour2,
          firstText: '战队管理',
          secondText: '合理规划战队活动，提升战队活跃度',
          
        },
        {
          id: 3,
          imageUrl: contentFour3,
          firstText:  '装备强化',
          secondText: '将每一件装备强化至顶级',
        },
        {
          id: 4,
          imageUrl: contentFour4,
          firstText: '家园升级',
          secondText: '提醒每一次炼丹注意事项',
        }
      ],
      fiveItems:[
        {
          id: 1,
          imageUrl: contentFive1,
          firstText: '夺宝规划',
          secondText: '最少的花销夺最好的宝物',
        }
        ,
        {
          id: 2,
          imageUrl: contentFive2,
          firstText: '挑战对手',
          secondText: '准时挑战队友，拿到奖励',
        },
        {
          id: 3,
          imageUrl: contentFive3,
          firstText: '阅读邮件',
          secondText: '及时关注官方邮件，得最新奖励',
        },
        {
          id: 4,
          imageUrl: contentFive4,
          firstText: '贡献仙盟',
          secondText: '不错过每一次仙盟活动，增加贡献值',
        }
      ],
      contactItems:[
        {
          id: 1,
          imageUrl: contactPhone,
          firstText: '19382213027',
          secondText: '最少的花销夺最好的宝物',
        }
        ,
        {
          id: 2,
          imageUrl: contactMail,
          firstText: 'yilian@nkfzs.com',
          secondText: '准时挑战队友，拿到奖励',
        },
      ]
    }
  }
}
</script>

<style scoped>.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  background-color: white;
  flex: 5%;
  flex-direction: row; /* 水平布局 */
  display: flex;
  align-items: center;
  padding-left: 130px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.contentContainer{
  flex: 94%;
  display: flex;
  flex-direction: column;
}

.content-first{
  background-image: url('../assets/bg-image.webp');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  box-sizing:border-box;
  height: 550px;
  padding-top: 100px;
  padding-left: 130px;
  padding-right: 40%; /* Adjusted padding-right */
  align-items: start;
  justify-content: start;
  text-align: start;
}

.content-second{
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}


.content-third{
  background-color: #E5F1FF;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.content-four{
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.content-five{
  background-color: #F2F2F2;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.content-six{
  background-color: #fff;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.content-seven{
  background-color: #E5F1FF;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding-top: 80px;
  padding-bottom: 100px;
}

.content-eight{
  background-color: #fff;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.image-container {
  margin-top: 50px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
   /* 使用 flex 布局 */
}

.image-container img {
  margin-right: 40px; /* 图片之间的间距，可以根据需要调整 */
  width: 280px;
  height: 350px;
}

.footer a {
  color: #fff; /* 将链接文字颜色设置为白色 */
}
.contact{
  display: flex; 
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.footer {
  background-color: #313131;
  flex: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  bottom: 0;
}
</style>
