<template>
  <div class="vertical-line-with-text">
    <div class="vertical-line" v-if="showVerticalLine"></div>
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
  export default {
    name: 'VerticalLineWithText',
    props: {
      text: {
      type: String,
      required: true
    },
    showVerticalLine: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.vertical-line-with-text {
  display: flex;
  align-items: center;
}

.vertical-line {
  width: 2px;
  height: 20px;
  background-color: black;
  margin-right: 10px; /* 调整竖线与文字的距离 */
  margin-left: 10px;
}

.text {
  /* 根据需要设置文字样式 */
  font-size: 30px;
  color: #666;
}
</style>
