<template>
  <div class="vertical-image-text">
    <img :src=imageUrl alt="Image" class="image" />
    <div class="text-first">{{ firstText }}</div>
    <div class="text-second">{{ secondText }}</div>
  </div>
</template>

<script>
  export default {
  name: 'VerticalLayout',
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    firstText: {
      type: String,
      required: true
    },
    secondText: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.vertical-image-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 130px;
  height: 130px;
}

.text-first {
  margin-top: 20px;
  color: #333;
  font-size: 20px;
  font-weight: 500;
  /* 根据需要设置文字样式 */
}
.text-second {
  margin-top: 10px;
  color: #797979;
  font-size: 14px;
  font-weight: 400;
  /* 根据需要设置文字样式 */
}
</style>
