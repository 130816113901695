<template>
  <div class="contact-text">
    <img  class="contact-icon" alt="Icon" :src="iconSrc">
    <span class="contact-text">{{ labelText }}</span>
  </div>
</template>

<script>
export default {
  props: {
    iconSrc: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.contact-text {
  display: flex;
  align-items: center;
  /* background-color: rebeccapurple; */
}

.contact-icon {
  width: 28px; /* 图标宽度 */
  height: 28px; /* 图标高度 */
  margin-right: 12px; /* 图标与文本间距 */
}

.contact-text {
  font-size: 14px; /* 文本字体大小 */
  color: #fff; /* 文本颜色 */
  font-weight: 400px;
}
</style>
