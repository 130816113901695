<template>
  <div class="icon-left-text">
    <img :src="iconSrc" class="icon" alt="Icon">
    <span class="text">{{ labelText }}</span>
  </div>
</template>

<script>
export default {
  props: {
    iconSrc: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.icon-left-text {
  display: flex;
  align-items: center;
  /* background-color: rebeccapurple; */
}

.icon {
  width: 34px; /* 图标宽度 */
  height: 34px; /* 图标高度 */
  margin-right: 10px; /* 图标与文本间距 */
}

.text {
  font-size: 20px; /* 文本字体大小 */
  color: #0F5EFF; /* 文本颜色 */
  font-weight: 500px;
}
</style>
